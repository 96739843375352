// import { useEffect } from 'react';
// import { useLocation, useNavigate, useParams } from 'react-router-dom';
// import i18n from 'i18next';
// import i18next from "i18next";

// function useLanguageUrl() {
//   const {pathname,state} = useLocation();
//   const navigate = useNavigate();
//   const { lang: _lang } = useParams();
//   const lang = _lang || "en";
  
//   useEffect(() => {
// 	if(lang === "en" || lang=== "sp")
//   {
//     i18next.changeLanguage(lang);
//   }
    
//     const path = pathname;
    
//     if (pathname === "/") {
//       navigate(`/${lang}`, {state})
//   }
//   const currentLang = i18n.language;
  
//   const languageSupport = ["en","sp"]


//     if (!languageSupport.includes(path.split('/')[1])) {

//    const secondSlashIndex = path.indexOf('/', path.indexOf('/') + 1); 
//    const hasSecondSlash = secondSlashIndex > -1; 
   
//    const thirdSlashIndex = path.indexOf('/', path.indexOf('/') + 2); 
//    const hasThirdSlash = thirdSlashIndex > -1; 
   
        
//       if(hasSecondSlash)
//       {
//         const newUrl = pathname.replace(/\/(?!en|sp)[^/]+/, '');
//         return navigate(`/${currentLang}${newUrl}`, {state})         
//       }
//       else if(hasThirdSlash)
//       {
//         const newUrl = pathname.replace(/\/(?!en|sp)[^/]+/, '');
//         return navigate(`/${currentLang}${newUrl}`, {state})  
//       }
//       else{
//         return navigate(`/${currentLang}${path}`, {state})         
//       }
//     }
    
// }, [pathname,navigate]);
// }

// export default useLanguageUrl;


import { useEffect } from "react";
import { useLocation ,useParams,useNavigate} from "react-router-dom";
import i18n from 'i18next';
import i18next from "i18next";

const useLanguageUrl = () => {
  
  const { lang: _lang } = useParams();
  const lang = _lang || "sp";
  
  const navigate = useNavigate();
  const { pathname } = useLocation();
  if(lang === "en" || lang=== "sp")
    {
      i18next.changeLanguage(lang);
    }
  // const currentLang = i18n.language;
  // Retrieve selected language from local storage or default to "en"
  const currentLang = localStorage.getItem("i18nextLng");

  // Check if the selected language is present in the URL
  const languageInUrl = pathname.split("/")[1];
  
  useEffect(() => {
    // If the selected language is not present in the URL
    if (!["en", "sp"].includes(languageInUrl)) {
      // Construct the modified URL with the selected language
      const modifiedUrl = `/${currentLang}${pathname}`;
      // Replace the current URL with the modified one
      return navigate(modifiedUrl);
    }
  }, [pathname, currentLang, languageInUrl]);
};

export default useLanguageUrl;
