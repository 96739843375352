import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Suspense, lazy } from "react";
import ScrollToTop from "components/ScrollToTop";
import PrivateRoute from "PrivateRoute";
import Language from "helpers/Language";

// Lazy loading components
const FlightBookingDetails = lazy(() => import("screens/FlightBookingDetails"));
const FlightDetail = lazy(() => import("screens/FlightDetail"));
const ForgotPassword = lazy(() => import("screens/ForgotPassword"));
const Login = lazy(() => import("screens/Login"));
const Otp = lazy(() => import("screens/Otp"));
const Profile = lazy(() => import("screens/Profile"));
const SearchFlight = lazy(() => import("screens/SearchFlight"));
const SignUp = lazy(() => import("screens/SignUp"));
const UpdatePassword = lazy(() => import("screens/UpdatePassword"));
const Bookings = lazy(() => import("./screens/Bookings"));
const ContactUs = lazy(() => import("./screens/ContactUs"));
const Community = lazy(() => import("./screens/Community"));
const AboutUs = lazy(() => import("screens/AboutUs"));
const TermsCondition = lazy(() => import("screens/TermsCondition"));
const Ticket = lazy(() => import("screens/Ticket"));
const StripeCheckout = lazy(() => import("components/StripeCheckout"));

function App() {
  return (
    <>
      <ScrollToTop />
      <Suspense fallback={<div id="cover-spin"></div>}>
        <Routes>
          {/* Public Routes */}
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/otp_verification" element={<Otp />} />
          <Route path="/forgot_password" element={<ForgotPassword />} />
          <Route path="/update_password" element={<UpdatePassword />} />
          <Route path="/" element={<SearchFlight />} />
          <Route path="*" element={<SearchFlight />} />
          <Route path="/ticket" element={<Ticket />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/flight_search" element={<SearchFlight />} />
          <Route path="/flight_details" element={<FlightDetail />} />
          <Route path="/terms_conditions" element={<TermsCondition />} />
          <Route path="/stripe" element={<StripeCheckout />} />
          <Route element={<PrivateRoute />}>
            <Route path="community" element={<Community />} />
            <Route path="bookings" element={<Bookings />} />
          </Route>

          {/* Language-specific Routes */}
          <Route path="/" element={<Language />}>
            <Route path="/:lang">
              {/* Nested public routes */}
              <Route path="" element={<SearchFlight />} />
              <Route path="*" element={<SearchFlight />} />
              <Route path="login" element={<Login />} />
              <Route path="signup" element={<SignUp />} />
              <Route path="about-us" element={<AboutUs />} />
              <Route path="otp_verification" element={<Otp />} />
              <Route path="forgot_password" element={<ForgotPassword />} />
              <Route path="update_password" element={<UpdatePassword />} />
              <Route path="ticket" element={<Ticket />} />
              <Route path="profile" element={<Profile />} />
              <Route path="contact-us" element={<ContactUs />} />
              <Route path="flight_search" element={<SearchFlight />} />
              <Route path="flight_details" element={<FlightDetail />} />
              <Route path="terms_conditions" element={<TermsCondition />} />

              {/* Private routes */}
              <Route element={<PrivateRoute />}>
                <Route path="community" element={<Community />} />
                <Route path="bookings" element={<Bookings />} />
              </Route>
            </Route>
          </Route>
        </Routes>
      </Suspense>
    </>
  );
}

export default App;
